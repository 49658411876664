import React from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import constants from "../res/constants";

import lander from "../res/homepage/Lander.jpg";

import QuadApes from "../QuadApes";
import ape1 from "../res/homepage/ape_1.jpg";
import ape2 from "../res/homepage/ape_2.jpg";
import ape3 from "../res/homepage/ape_3.jpg";
import ape4 from "../res/homepage/ape_4.jpg";

const { color, font, fontsize } = constants;

const Section = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap-reverse;

  div:last-child {
    flex: 1 1 50%;

    p {
      margin-bottom: 0;
    }

    ${(props) =>
      props.isMobile
        ? "flex-basis: 100%;"
        : "margin: 64px 16px; padding: 0 15px 0;"}
  }
`;

const Lander = styled.div`
  width: 100%;
  img {
    height: auto;
    width: 100%;
  }
`;

const ListAction = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${color.gold};
  padding: 24px;
  text-align: center;

  ${(props) => (props.isMobile ? "margin-top: 48px;" : "")}

  h1 {
    white-space: nowrap;
    padding: 0 16px;
  }

  p {
    text-align: center;
    padding: 0 16px;
    margin: 0 16px;
  }

  div {
    margin: 24px;
    a {
      background-color: ${color.blue};
      padding: 16px 64px;
      margin: 16px;
      border-radius: 8px;
      font-weight: 600;
      cursor: pointer;
      transition: background-color 0.2s, color 0.2s;

      :hover {
        background-color: ${color.white};
        color: ${color.blue};
      }
    }
  }

  > * {
    flex: 1;
  }

  ${(props) => (props.isMobile ? "flex-direction: column" : "")}
`;

function Welcome() {
  const isMobile = !useMediaQuery({ minWidth: 992 });

  return (
    <>
      <Lander>
        <img src={lander} />
      </Lander>
      <Section isMobile={isMobile}>
        <QuadApes apes={[ape1, ape2, ape3, ape4]} />
        <div>
          <h1>Welcome to The Powder Club</h1>

          <p>BAPC is a curated selection of 8888 unique NFTs.</p>
          <p>
            Your Ape gets you in to The Backroom of The Powder Club, a luxury
            lifestyle community for the cultured Ape.
          </p>

          <p>
            What happens in the Backroom is strictly hush-hush.
            <br />
            Come on in and have a drink. Our bartender is exquisite.
          </p>
        </div>
      </Section>
      <ListAction isMobile={isMobile}>
        <h1>GET ON THE LIST</h1>
        <p>
          Our mint date is coming soon, so the best way to get on the list is to
          join our Discord community and follow us on twitter.
        </p>
        <div>
          <a>MINT</a>
        </div>
      </ListAction>
    </>
  );
}

export default Welcome;
