import React from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

import constants from "./res/constants";
import Footer from "./Footer";
import Socials from "./Socials";

import background from "./res/landingpage/background.jpg";
import logo from "./res/landingpage/logo.png";

const { color } = constants;
const Page = styled.div`
  min-height: 100vh;
  overflow: hidden;
  background-color: ${color.black};
  color: ${color.white};
  display: flex;
  flex-flow: column;
`;

const Body = styled.div`
  position: relative;
  max-width: 100%;
  align-self: center;
  flex: 1;
  > p {
    text-align: right;
    margin: 32px 32px 0;
  }

  > div:nth-child(2) {
    position: relative;
    display: flex;
    flex-direction: column;
  }
`;

const Header = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  justify-content: center;
  padding: 24px;
  z-index: 1;

  > img {
    height: auto;
    max-width: 300px;
    align-self: flex-start;
    width: 100%;
  }

  > div {
    position: absolute;
    right: 3%;
    top: 24px;
  }
`;

const Picture = styled.img`
  height: auto;
  max-width: 1500px;
  width: 100%;
  padding: ${({ isMobile }) => (isMobile ? "64px 0 0" : "64px 48px 0")};
`;

const Action = styled.div`
  background: ${color.black};
  display: flex;
  flex-flow: column;
  max-width: 500px;
  right: 0;
  bottom: 0;
  position: ${({ isMobile }) => (isMobile ? "initial" : "absolute")};
  align-self: flex-end;
  padding: 32px;

  p {
    font-style: italic;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 31px;
    text-align: right;
    margin: 0 0 32px;
  }

  a {
    background-color: ${color.gold};
    color: ${color.white};
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    align-self: stretch;
    padding: 5%;
    border-radius: 8px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s, color 0.2s;

    :hover {
      background-color: ${color.white};
      color: ${color.gold};
    }
  }
`;

function LandingPage() {
  const isMobile = !useMediaQuery({ minWidth: 992 });

  return (
    <Page>
      <Body>
        <Header isMobile={isMobile}>
          <img src={logo} />
          {!isMobile && <Socials />}
        </Header>
        <div>
          <Picture src={background} isMobile={isMobile} />
          <Action isMobile={isMobile}>
            <p>Welcome to{!isMobile && <br />} the Powder Club</p>
            <Link to="home">Enter</Link>
          </Action>
        </div>
        <p>
          A limited NFT Collection that grants you access to The Backroom of The
          Powder Club. <br />
          Do you enjoy the finer things in life? <br />
          Us too. Come on in and enjoy yourself.
        </p>
      </Body>
      <Footer />
    </Page>
  );
}

export default LandingPage;
