import React from "react";
import styled from "styled-components";

import ape_art from "../res/homepage/ape_art.jpg";

const Section = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: ${({isMobile}) => isMobile ? "0" : "0 32px"};
`;

const Text = styled.div`
  flex: 1 1 55%;
  margin: 0 16px;

  h1 {
    margin-top: 0;
  }
`;

const Image = styled.div`
  flex: 1 1 35%;
  margin: 0 32px;
  margin-top: ${({isMobile}) => isMobile ? "32px" : "0"};

  img {
    width: 100%;
    height: auto;
  }
`;

function Art({isMobile}) {

  return (
    <Section isMobile={isMobile}>
      <Text isMobile={isMobile}>
        <h1>THE ART</h1>
        <p>
          Each Backroom Ape is made from a curated selection of 123 possible
          traits. We have a variety of characters who attend the Powder Club.
          Some are fine socialites and other’s might be having too much fun in
          the backroom...
        </p>
        <p>
          Your membership card is stored as an ERC-721A token on the Ethereum
          blockchain and hosted on IPFS.
        </p>
        <p>
          Only with this token will you able granted access to the Backroom of
          The Powder Club.
        </p>
      </Text>
      <Image isMobile={isMobile}>
        <img src={ape_art} />
      </Image>
    </Section>
  );
}

export default Art;
