import React from "react";
import styled from "styled-components";
import constants from "../res/constants";

import roadmap from "../res/homepage/Roadmap.png";

const Section = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: ${({ isMobile }) => (isMobile ? "0" : "0 48px")};
`;

const Text = styled.div`
  flex: 1 1 50%;

  h1 {
    margin-top: 0;
  }

  p {
    margin-bottom: 0;
  }
`;

const Image = styled.div`
  flex: 1 1 45%;
  margin: ${({ isMobile }) => (isMobile ? "24px 0 0" : "0 0 0 48px")};
  ${({ isMobile }) => (isMobile ? "flex-basis: 100%;" : "")}

  img {
    width: 100%;
    height: auto;
  }
`;

function Roadmap({ id, isMobile }) {
  return (
    <Section id={id} isMobile={isMobile}>
      <Text>
        <h1>Roadmap</h1>
        <p>
          We’re building a community that successfully grows as Web3 evolves.
          <br />
          Things move fast in today’s age and we believe in moving in accordance
          with them.
        </p>
        <p>
          That means fluid ideas with short-term deliverables.
          <br /> That means building trust through action and transparency.
          <br /> That means under-promise and over-deliver.
        </p>
        <p>
          That being said... <br />
          We have several plans to upgrade The Powder Club in the coming months
          that we’re going to keep close to our chest. On a need-to-know
          basis... You being new, and all...
        </p>
        <p>
          We also like to move very fast, so I would recommend you hustle.{" "}
          <br />
          Join our Discord for the latest roadmap objective.
        </p>
      </Text>
      <Image isMobile={isMobile}>
        <img src={roadmap} />
      </Image>
    </Section>
  );
}

export default Roadmap;
