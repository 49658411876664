import React from "react";
import styled from "styled-components";

import constants from "../res/constants";

import cocktails from "../res/homepage/cocktails.png";
import food from "../res/homepage/food.png";
import amenities from "../res/homepage/amenities.png";

const Section = styled.div`
  margin: ${({ isMobile }) => (isMobile ? "0" : "0 48px")};
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
`;

const Column = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${(props) => (props.isMobile ? "row" : "column")};

  flex: 0 1 25%;

  text-align: center;

  img {
    width: 100%;
    height: auto;
    max-width: 80%;
    ${(props) => (props.isMobile ? "max-width: 25%;" : "")}
  }
`;

const Text = styled.div`
  p {
    &:first-of-type {
      text-decoration: underline;
    }

    margin-bottom: 0;
  }
`;

function PowderClub({ id, isMobile }) {
  return (
    <Section id={id} isMobile={isMobile}>
      <h1>What's the powder club?</h1>
      <p>
        A premier luxury lifestyle community of patrons who enjoy the finer
        things in life. Taste and culture spans the metaverse... So should you.
        Here’s just a taste of what’s happening in the Backroom:
      </p>
      <Row isMobile={isMobile}>
        <Column isMobile={isMobile}>
          <img src={cocktails} />
          <Text>
            <p>Cocktail Hours</p>
            <p>
              Expert mixologists teach you how to make your favorite cocktail at
              home.
            </p>
            <p>
              Featuring:
              <br />
              Livestreams, Films, AMAs, and Podcasts following the world’s
              finest beverages.
            </p>
          </Text>
        </Column>
        <Column isMobile={isMobile}>
          <img src={amenities} />
          <Text>
            <p>Luxurious Amenities</p>
            <p>
              From exceptional garments to the most exotic vehicles, BAPC has
              taste & culture.
            </p>
            <p>
              Featuring:
              <br />
              Interviews with top designers, artists, and aficionados, & more
            </p>
          </Text>
        </Column>
        <Column isMobile={isMobile}>
          <img src={food} />
          <Text>
            <p>The Finest Eats & Drinks</p>
            <p>
              Have you ever had mindblowing espresso? Let’s share our little
              secrets.
            </p>
            <p>
              Featuring:
              <br />
              The pulse on the best ingestibles on the planet. Come eat with us.
            </p>
          </Text>
        </Column>
      </Row>
    </Section>
  );
}

export default PowderClub;
