import React from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

import constants from "../res/constants";

import Header from "./Header";
import Welcome from "./Welcome";
import Art from "./Art";
import PowderClub from "./PowderClub";
import Roadmap from "./Roadmap";
import Team from "./Team";
import Footer from "../Footer";

const { color, font, fontsize } = constants;
const Page = styled.div`
  min-height: 100vh;
  overflow: hidden;
  background-color: ${color.black};
  color: ${color.white};
`;

const Body = styled.div`
  max-width: 1200px;
  margin: 0 auto 0 auto;
  padding: 0 24px;
`;

const HR = styled.hr`
  margin: 48px 0;
  width: 100%;
`;

function HomePage() {
  const isMobile = !useMediaQuery({ minWidth: 992 });

  return (
    <Page>
      <Body>
        <Header isMobile={isMobile} />
        <Welcome />
        <HR />
        <Art isMobile={isMobile} />
        <HR />
        <PowderClub isMobile={isMobile} id="join" />
        <HR />
        <Roadmap isMobile={isMobile} id="roadmap" />
        <HR />
        <Team isMobile={isMobile} id="team" />
      </Body>
      <Footer />
    </Page>
  );
}

export default HomePage;
