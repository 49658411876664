import React from "react";
import styled from "styled-components";
import QuadApes from "../QuadApes";
import constants from "../res/constants";

import ape5 from "../res/homepage/ape_5.jpg";
import ape6 from "../res/homepage/ape_6.jpg";
import ape7 from "../res/homepage/ape_7.jpg";
import ape8 from "../res/homepage/ape_8.jpg";

const { color } = constants;

const Section = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: ${({ isMobile }) => (isMobile ? "0" : "0 48px")};
`;

const Text = styled.div`
  flex: 1 1 54%;

  h1 {
    margin-top: 0;
  }

  p {
    margin-bottom: 0;
  }
`;

const Name = styled.span`
  color: ${color.gold};
`;

const Dox = styled.a`
  color: ${color.white};
  :hover {
    font-weight: 800;
  }
`;

function Team({ id, isMobile }) {
  return (
    <Section id={id} isMobile={isMobile}>
      <Text>
        <h1>Team</h1>
        <p>
          Our team set out to create the smoothest NFT project launch of all
          time. <br />
          We spent extra time and resources ensuring that our contract and
          community are solid, efficient, and well tested. <br />
          First impressions are everything. We prefer a firm handshake.
        </p>
        <p>
          <Name>Evan Buzzell </Name>
          <i>
            - Runs the joint. Usually with the VIPs.{" "}
            <Dox href="And plenty more who helped us create this project along the way.">
              Doxxed.
            </Dox>
          </i>
          <br />
          <Name>Swazzy22</Name>
          <i>- Lead Bartender. Crafting up the collection & a few cocktails.</i>
          <br />
          <Name>Cheese</Name>
          <i> - The errand boy sent by grocery clerks... to collect a bill.</i>
          <br />
          <Name>K12ish</Name>
          <i>
            - Head of Security. Tight ship. Don’t make him pull out the gloves.
          </i>
          <br />
          <Name>Teev</Name>
          <i> - Sous Chef. Always in the kitchen cookin’ alpha.</i>
          <br />
          <Name>Steve Saiz</Name>
          <i>
            {" "}
            - Our legendary artist. Check him out:{" "}
            <Dox href="http://stevesaiz.la/">Doxxed.</Dox>
          </i>
          <br />
          <Name>EpiKahn</Name>
          <i> - The fat-cat in the back with the pet ape countin’ money.</i>
          <br />
          <Name>Millycoin</Name>
          <i> - Our resident DJ. Fingers in all the right pies, iykwim...</i>
          <br />
          <Name>Zione</Name>
          <i> - Our sommelier. Knows a guy. Prego.</i>
          <br />
        </p>
        <p>And plenty more who helped us create this project along the way.</p>
      </Text>
      <QuadApes apes={[ape5, ape6, ape7, ape8]} />
    </Section>
  );
}

export default Team;
