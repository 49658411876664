import React from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, [col-start] 1fr);
  grid-gap: 10px;
  flex: 1 0 30%;
  margin: ${(props) => (props.isMobile ? "48px 0 0" : "32px")};
  ${({ isMobile }) => (isMobile ? "flex-basis: 100%;" : "")}


  img {
    width: 100%;
    height: auto;
  }
`;

function QuadApes({ apes }) {
  const isMobile = !useMediaQuery({ minWidth: 992 });

  return (
    <Container isMobile={isMobile}>
      {apes.map(ape => 
      <img key={ape} src={ape} />
        )}
    </Container>
  );
}

export default QuadApes;