import React from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

import constants from "./res/constants";
import logo from "./res/homepage/footer_logo.png";
import Socials from "./Socials";

const { color } = constants;
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 48px auto;
  padding-top: 48px;
  width: 90%;
  border-top: 1px solid ${color.white};
  ${(props) => (props.isMobile ? "flex-flow: column;" : "")}
  ${(props) => (props.isMobile ? "text-align: center;" : "")}
`;

const MailChimp = styled.div`
  align-self: stretch;
  > p {
    margin-top: 0;
    text-transform: uppercase;
    font-weight: bold;
    font-style: italic;
    margin-bottom: 16px;
  }
`;

const Logo = styled.img`
  flex: 1 1 90px;
  max-width: 90px;
  height: auto;

  ${(props) => (props.isMobile ? "margin: 24px 0;" : "")}
`;

const Copyright = styled.div`
  display: flex;
  flex-flow: column;
  align-items: ${(props) => (props.isMobile ? "center" : "flex-end")};
  text-transform: uppercase;
  color: ${color.gold};
  font-size: 14px;
  font-weight: bold;

  p {
    margin: 0;
    :last-child {
      text-decoration: underline;
    }
  }

  a {
    margin-top: 0;
  }
`;

const PageEnd = styled.div`
  height: 16px;
  background-color: ${color.gold};
`;

const createMailChimp = () => ({
  __html: `
  <div id="mc_embed_signup">
<form action="https://bapowderclub.us14.list-manage.com/subscribe/post?u=ca6309a0273b6f0b4411d5f90&amp;id=3399eab445" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
    <div id="mc_embed_signup_scroll">
	
	<input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL" placeholder="Email address">
	<div id="mce-responses" class="clear foot">
		<div class="response" id="mce-error-response" style="display:none"></div>
		<div class="response" id="mce-success-response" style="display:none"></div>
	</div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
    <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_ca6309a0273b6f0b4411d5f90_3399eab445" tabindex="-1" value=""></div>
        <div class="optionalParent">
              <input type="submit" value="→" name="subscribe" id="mc-embedded-subscribe" class="button">
            </div>
        </div>
    </div>
</form>
</div>`,
});

function Footer() {
  const isMobile = !useMediaQuery({ minWidth: 992 });

  return (
    <div>
      <Container isMobile={isMobile}>
        <MailChimp>
          <p>Get on the List</p>
          <div dangerouslySetInnerHTML={createMailChimp()} />
        </MailChimp>
        <Logo src={logo} isMobile={isMobile} />
        <Copyright isMobile={isMobile}>
          <Socials />
          <p>© 2022 Viscerah</p>
          <p>BAPC Terms & Conditions</p>
        </Copyright>
      </Container>
      <PageEnd />
    </div>
  );
}

export default Footer;
