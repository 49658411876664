import React from "react";
import styled from "styled-components";

import constants from "./res/constants";
import { ReactComponent as Youtube } from "./res/youtube.svg";
import { ReactComponent as Instagram } from "./res/instagram.svg";
import { ReactComponent as Discord } from "./res/discord.svg"
import { ReactComponent as Twitter } from "./res/twitter.svg"

const { color } = constants;

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
`;

const Social = styled.a`
  cursor: pointer;
  margin: 12px;
  svg {
    width: 24px;
    height: 24px;
    fill: white;
    transition: fill 0.2s;

    :hover {
      fill: ${color.gold};
    }
  }
`;

function Socials() {
  return (
    <Container>
      <Social href={"https://www.youtube.com/channel/UC2sxcP0oz6vqGSTof9ZxMYg"}>
        <Youtube />
      </Social>
      <Social href={"https://www.instagram.com/bapc8888/"}>
        <Instagram />
      </Social>
      <Social href={"https://discord.gg/thepowderclub"}>
        <Discord />
      </Social>
      <Social href={"https://twitter.com/BAPC8888"}>
        <Twitter />
      </Social>
    </Container>
  );
}

export default Socials;