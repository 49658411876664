import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import constants from "../res/constants";
import logo from "../res/homepage/BAPC_Header_short.png";
import { ReactComponent as MenuIcon } from "../res/menu.svg";

import Socials from "../Socials";

const { color } = constants;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding: ${({ isMobile }) => (isMobile ? "32px 0" : "32px")};
`;

const Logo = styled.img`
  flex: 0 1 194px;
  max-width: 194px;
  height: auto;
  cursor: pointer;
`;

const Navbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;

  ${({ isMobile }) =>
    isMobile
      ? "flex-direction: column; align-items: flex-end; flex-basis: 100%;"
      : ""}
`;

const NavItem = styled(HashLink).attrs({ smooth: true })`
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  color: ${color.white};
  transition: color 0.2s;
  padding: 8px 24px;

  :hover {
    color: ${color.gold};
  }

  :not(:last-of-type) {
    border-right: solid 2px gray;
  }
`;

const Menu = styled.button`
  cursor: pointer;
  border: none;
  padding: 16px 0 16px 16px;
  margin-left: auto;
  background-color: rgba(0, 0, 0, 0);

  svg {
    stroke: ${color.white};
    transition: stroke 0.2s;
    :hover {
      stroke: ${color.gold};
    }
  }
`;

function Header({ isMobile }) {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const navigate = useNavigate();

  return (
    <Container isMobile={isMobile}>
      <Logo src={logo} onClick={() => navigate("/")} />
      {!isMobile ? (
        <Navbar>
          <NavItem to="/home#join">
            Join the Club
          </NavItem>
          <NavItem to="/home#roadmap">Roadmap</NavItem>
          <NavItem to="/home#team">Team</NavItem>
          <Socials />
        </Navbar>
      ) : (
        <Menu onClick={() => setMenuOpen((menuOpen) => !menuOpen)}>
          <MenuIcon />
        </Menu>
      )}
      {isMobile && menuOpen && (
        <Navbar isMobile={isMobile}>
          <NavItem to="/home#join">
            Join the Club
          </NavItem>
          <NavItem to="/home#roadmap">Roadmap</NavItem>
          <NavItem to="/home#team">Team</NavItem>
          <Socials />
        </Navbar>
      )}
    </Container>
  );
}

export default Header;
