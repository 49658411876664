import React from "react";
import { Routes, Route } from "react-router-dom";

import LandingPage from "./LandingPage";
import HomePage from "./homepage/HomePage";

function App() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/home" element={<HomePage />} />
    </Routes>
  );
}

export default App;
